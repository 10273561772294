import { EditUserComponent } from './users/edit-user/edit-user.component';
import { AuthguardService } from './../services/Authguard/authguard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './shared/admin-layout/admin-layout.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthComponent } from './auth/auth/auth.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AddUserComponent } from './users/add-user/add-user.component';
import { RegisterComponent } from './auth/register/register.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthloginService } from '../services/Authguard/authlogin.service';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { BusinessUserListComponent } from './businessUser/business-user-list/business-user-list.component';
import { AddBusinessUserComponent } from './businessUser/add-business-user/add-business-user.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { AddCustomerComponent } from './customer/add-customer/add-customer.component';
import { BreedListComponent } from './breed/breed-list/breed-list.component';
import { AddBreedComponent } from './breed/add-breed/add-breed.component';
import { VaccineListComponent } from './vaccines/vaccine-list/vaccine-list.component';
import { AddVaccinesComponent } from './vaccines/add-vaccines/add-vaccines.component';
import { DeliveryListComponent } from './delivery/delivery-list/delivery-list.component';
import { AddDeliveryComponent } from './delivery/add-delivery/add-delivery.component';
import { VaccineGroupListComponent } from './vaccineGroup/vaccine-group-list/vaccine-group-list.component';
import { AddVaccineGroupComponent } from './vaccineGroup/add-vaccine-group/add-vaccine-group.component';
import { NearbyVetListComponent } from './nearbyVet/nearby-vet-list/nearby-vet-list.component';
import { NearbyVetsListComponent } from './nearByVets/nearby-vets-list/nearby-vets-list.component';
import { NearbyAppointmentComponent } from './appointment/nearby-appointment/nearby-appointment.component';
import { OrdersComponent } from './order/orders/orders.component';
import { ListingComponent } from './listing/listing.component';
import { HelpComponent } from './help/help.component';
import { RxListingComponent } from './RxManager/rx-listing/rx-listing.component';
import { RxViewComponent } from './RxManager/rx-view/rx-view.component';
import { ListVendorComponent } from './vendor/list-vendor/list-vendor.component';
import { AddVendorComponent } from './vendor/add-vendor/add-vendor.component';
import { ListConsultingComponent } from './consulting/list-consulting/list-consulting.component';
import { ListInventoryComponent } from './inventory/list-inventory/list-inventory.component';
import { AddInventoryComponent } from './inventory/add-inventory/add-inventory.component';
import { VendorServicesService } from '../services/vendorServices/vendor-services.service';
import { AddAccessoriesComponent } from './accessories/add-accessories/add-accessories.component';
import { ListAccessoriesComponent } from './accessories/list-accessories/list-accessories.component';
import { AddDeliveryGuyComponent } from './delivery-guy/add-delivery-guy/add-delivery-guy.component';
import { ListDeliveryGuyComponent } from './delivery-guy/list-delivery-guy/list-delivery-guy.component';
import { ListOrderComponent } from './vendor-order/list-order/list-order.component';
import { ListPaymentComponent } from './payment/list-payment/list-payment.component';
import { ListBreedComponent } from './cat-breed/list-breed/list-breed.component';
import { CatAddBreedComponent } from './cat-breed/cat-add-breed/cat-add-breed.component';
import { CatListVaccineComponent } from './cat-vaccine/cat-list-vaccine/cat-list-vaccine.component';
import { CatAddVaccineComponent } from './cat-vaccine/cat-add-vaccine/cat-add-vaccine.component';
import { AddLocationComponent } from './location/add-location/add-location.component';
import { ListLocationComponent } from './location/list-location/list-location.component';
import { AddClientComponent } from './client/add-client/add-client/add-client.component';
import { EditClientComponent } from './client/edit-client/edit-client/edit-client.component';
import { ListClientComponent } from './client/list-client/list-client/list-client.component';

const routes: Routes = [
        
      
      {
        path: '',
        component:AuthComponent,
        children: [
          {
            path: 'login',
            canActivate:[AuthloginService],
            component: LoginComponent,
          },
          {
            path: 'forgot',
            canActivate:[AuthloginService],
            component: ForgotPasswordComponent,
          },
          {
            path: 'reset/:id',
            component: ResetPasswordComponent,
          },
          {
            path: 'register',
            canActivate:[AuthloginService],
            component: RegisterComponent,
          },
          {
            path: '',
            canActivate:[AuthloginService],
            component: LoginComponent,
          },
      ]
      },
      {
        path: '',
        component:AdminLayoutComponent,
        children: [
          {
            path: 'dashboard',
            component:DashboardComponent,
            canActivate:[AuthguardService],
          },
          {
            path: 'profile',
            component:ProfileComponent,
            canActivate:[AuthguardService],
          },
          {
            path: 'changepassword',
            component:ChangePasswordComponent,
            canActivate:[AuthguardService],
          },
          {
            path: 'subadmin',
            canActivate:[AuthguardService],
            children: [
              {
                path: '',
                component : UserListComponent
              },
              {
                path: 'list',
                component : UserListComponent
              },
              {
                path: 'add',
                component : AddUserComponent
              },
              {
                path: 'edit/:userId',
                component : EditUserComponent
              },
              {
                path: '**',
                component : UserListComponent
              },
            ]
          },

          {
            path: 'businessUser',
            canActivate:[AuthguardService],
            children: [
              {
                path: '',
                component : BusinessUserListComponent
              },
              {
                path: 'list',
                component : BusinessUserListComponent
              },
              {
                path: 'add',
                component : AddBusinessUserComponent
              },
              {
                path: 'edit/:id',
                component : AddBusinessUserComponent
              },
              {
                path: '**',
                component : BusinessUserListComponent
              },
            ]
          },
          {
            path: 'vendor',
            canActivate:[AuthguardService],
            children: [
              {
                path: '',
                component : ListVendorComponent
              },
              {
                path: 'list',
                component : ListVendorComponent
              },
              {
                path: 'add',
                component : AddVendorComponent
              },
              {
                path: 'edit/:id',
                component : AddVendorComponent
              },
              {
                path: '**',
                component : ListVendorComponent
              },
            ]
          },
          {
            path: 'customer',
            canActivate:[AuthguardService],
            children: [
              {
                path: '',
                component : CustomerListComponent
              },
              {
                path: 'list',
                component : CustomerListComponent
              },
              {
                path: 'add',
                component : AddCustomerComponent
              },
              {
                path: 'edit/:id',
                component : AddCustomerComponent
              },
              {
                path: '**',
                component : CustomerListComponent
              },
            ]
          },
          {
            path: 'client',
            canActivate:[AuthguardService],
            children: [
              {
                path: '',
                component : ListClientComponent
              },
              {
                path: 'list',
                component : ListClientComponent
              },
              {
                path: 'add',
                component : AddClientComponent
              },
              {
                path: 'edit/:id',
                component : EditClientComponent
              },
              {
                path: '**',
                component : ListClientComponent
              },
            ]
          },
          {
            path: 'delivery',
            canActivate:[AuthguardService],
            children: [
              {
                path: '',
                component : DeliveryListComponent
              },
              {
                path: 'list',
                component : DeliveryListComponent
              },
              {
                path: 'add',
                component : AddDeliveryComponent
              },
              {
                path: 'edit/:id',
                component : AddDeliveryComponent
              },
              {
                path: '**',
                component : DeliveryListComponent
              },
            ]
          },
          
          {
            path: 'breed',
            canActivate:[AuthguardService],
            children: [
              {
                path: '',
                component : BreedListComponent
              },
              {
                path: 'list',
                component : BreedListComponent
              },
              {
                path: 'add',
                component : AddBreedComponent
              },
              {
                path: 'edit/:id',
                component : AddBreedComponent
              },
              {
                path: '**',
                component : BreedListComponent
              },
            ]
          },

          {
            path: 'vaccineSuggestion',
            canActivate:[AuthguardService],
            children: [
              {
                path: '',
                component : VaccineListComponent
              },
              {
                path: 'list',
                component : VaccineListComponent
              },
              {
                path: 'add',
                component : AddVaccinesComponent
              },
              {
                path: 'edit/:id',
                component : AddVaccinesComponent
              },
              {
                path: '**',
                component : VaccineListComponent
              },
            ]
          },
          {
            path: 'vaccineGroup',
            canActivate:[AuthguardService],
            children: [
              {
                path: '',
                component : VaccineGroupListComponent
              },
              {
                path: 'list',
                component : VaccineGroupListComponent
              },
              {
                path: 'add',
                component : AddVaccineGroupComponent
              },
              {
                path: 'edit/:id',
                component : AddVaccineGroupComponent
              },
              {
                path: '**',
                component : VaccineGroupListComponent
              },
            ]
          },


          {
            path: 'consulting',
            canActivate:[AuthguardService],
            children: [
              {
                path: '',
                component : ListConsultingComponent
              },
              {
                path: 'list',
                component : ListConsultingComponent
              },
             
             
            ]
          },





          {
            path: 'payment',
            component : ListPaymentComponent
            // canActivate:[AuthguardService],
            
          },










          {
            path: 'listing',
            component : ListingComponent
            // canActivate:[AuthguardService],
            
          },
         
          {
            path: 'nearByVets',
            component : NearbyVetsListComponent
            // canActivate:[AuthguardService],
            
          },
          {
            path: 'nearByAppointments',
            component : NearbyAppointmentComponent
            // canActivate:[AuthguardService],
            
          },
          {
            path: 'orders',
            component : OrdersComponent
            // canActivate:[AuthguardService],
            
          },
          {
            path: 'help',
            component : HelpComponent
            // canActivate:[AuthguardService],
            
          },



       
          {
            path: 'food',
            canActivate:[VendorServicesService],
            children: [
              {
                path: '',
                component : ListInventoryComponent
              },
              {
                path: 'list',
                component : ListInventoryComponent
              },
              {
                path: 'add',
                component : AddInventoryComponent
              },
              {
                path: 'edit/:id',
                component : AddInventoryComponent
              },
              {
                path: '**',
                component : ListInventoryComponent
              },
            ]
          },



          {
            path: 'accessories',
            canActivate:[],
            children: [
              {
                path: '',
                component : ListAccessoriesComponent
              },
              {
                path: 'list',
                component : ListAccessoriesComponent
              },
              {
                path: 'add',
                component : AddAccessoriesComponent
              },
              {
                path: 'edit/:id',
                component : AddAccessoriesComponent
              },
              {
                path: '**',
                component : ListAccessoriesComponent
              },
            ]
          },



          {
            path: 'deliverys',
            canActivate:[],
            children: [
              {
                path: '',
                component : ListDeliveryGuyComponent
              },
              {
                path: 'list',
                component : ListDeliveryGuyComponent
              },
              {
                path: 'add',
                component : AddDeliveryGuyComponent
              },
              {
                path: 'edit/:id',
                component : AddDeliveryGuyComponent
              },
              {
                path: '**',
                component : ListDeliveryGuyComponent
              },
            ]
          },


          {
            path: 'order',
            canActivate:[],
            children: [
              {
                path: '',
                component : ListOrderComponent
              },
              {
                path: 'list',
                component : ListOrderComponent
              },
             
              {
                path: '**',
                component : ListOrderComponent
              },
            ]
          },

          {
            path: 'location',
            canActivate:[],
            children: [
              {
                path: '',
                component : ListLocationComponent
              },
              {
                path: 'list',
                component : ListLocationComponent
              },
              {
                path: 'add',
                component : AddLocationComponent
              },
             
              {
                path: '**',
                component : ListLocationComponent
              },
            ]
          },













          
   
          {
            path: 'cat-breed',
            canActivate:[AuthguardService],
            children: [
              {
                path: '',
                component : ListBreedComponent
              },
              {
                path: 'list',
                component : ListBreedComponent
              },
              {
                path: 'add',
                component : CatAddBreedComponent 
              },
              {
                path: 'edit/:id',
                component : CatAddBreedComponent
              },
              {
                path: '**',
                component : ListBreedComponent
              },
            ]
          },



          {
            path: 'cat-vaccine',
            canActivate:[AuthguardService],
            children: [
              {
                path: '',
                component : CatListVaccineComponent
              },
              {
                path: 'list',
                component : CatListVaccineComponent
              },
              {
                path: 'add',
                component : CatAddVaccineComponent 
              },
              {
                path: 'edit/:id',
                component : CatAddVaccineComponent
              },
              {
                path: '**',
                component : CatListVaccineComponent
              },
            ]
          },

          {
            path: 'RxManager',
            canActivate:[AuthguardService],
            children: [
              {
                path: '',
                component : RxListingComponent
              },
              {
                path: 'list',
                component : RxListingComponent
              },
           
              {
                path: 'view/:id',
                component : RxViewComponent
              },
              {
                path: '**',
                component : RxListingComponent
              },
            ]
          },
  
      ]
    },
     
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
