import { Component } from '@angular/core';
import { LoaderService } from './services/loader/loader.service';
import { FileUploadService } from './services/fileUpload/file-upload.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Cure Human-admin';
  loadingFlag:boolean = false;
  lottieConfig: any;
  anim: any;

  constructor(
    public loader:LoaderService,
    public uploadService:FileUploadService,

  ){
    let self =this;
  document.addEventListener('click',function(){
      if(self.loader.dropdownTimer == true){
        self.loader.dropdown = false;
        self.loader.dropdownTimer = false;
      }
  })

  setTimeout(() => {
    this.loadingFlag = true;
  }, 2000);
  this.lottieConfig = {
    path: 'assets/lottie/loader.json',
    renderer: 'canvas',
    autoplay: true,
    loop: true
};
  }
  animationCreated(anim: any) {
    this.anim = anim;
}
}
