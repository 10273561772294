<div class="mat-card card">

    <div class="row">
        <div class="col-8">
            <h2 class="main-heading">
                Manage Clients

            </h2>
        </div>
        <div class="col-4">
            <button mat-button class="submit" (click)="addclient()">Add Client</button>

        </div>
    </div>
     <div class="matTableDiv">
        <table mat-table matSort [dataSource]="reserveData" class="mat-elevation-z8">
    
    
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element" id="Name">
              <ng-container *ngIf="element.name == undefined || element.name == '';else Name">NA</ng-container>
              <ng-template #Name>{{element.name}}</ng-template>
            </td>
          </ng-container>
          <!-- <ng-container matColumnDef="manname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Manager Name </th>
            <td mat-cell *matCellDef="let element" id="name">
              <ng-container *ngIf="element.managerId.name == undefined || element.managerId.name == '';else Name">NA</ng-container>
              <ng-template #Name>{{element.managerId.name}}</ng-template>
            </td>
          </ng-container>
    
          <ng-container matColumnDef="manemail">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Manager Email </th>
            <td mat-cell *matCellDef="let element" id="email">
              <ng-container *ngIf="element.managerId.email == undefined || element.managerId.email == '';else email">NA</ng-container>
              <ng-template #email>{{element.managerId.email}}</ng-template>
            </td>
          </ng-container>
    
          <ng-container matColumnDef="manmobile">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Manager Mobile </th>
            <td mat-cell *matCellDef="let element" id="mobile">
              <ng-container *ngIf="element.managerId.mobile == undefined || element.managerId.mobile == '';else mobile">NA</ng-container>
              <ng-template #mobile>{{element.managerId.mobile}}</ng-template>
            </td>
          </ng-container>
    
          <ng-container matColumnDef="branch">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Branch </th>
            <td mat-cell *matCellDef="let element">
              <span>{{element.branch}}</span>
            </td>
          </ng-container>
    
     -->
        
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <span class="material-icons editDelete" (click)="edit(element._id)" title="Edit">edit</span>
              <span class="material-icons editDelete" (click)="deleteclient(element._id)" title="Delete">delete</span>
    
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns">
    
          </tr>
          <tr class="data" mat-row *matRowDef="let row; columns: displayedColumns;">
    
          </tr>
    
        </table>
        <mat-paginator [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event">
      </mat-paginator>
      </div> 
    
</div>
