
export const ROUTES:any = [
  {
    path: '/admin/dashboard',
    title: 'Dashboard',
    icon: 'home',
    class: '',
    submenu: [],
    expand : false
  },
  {
    path: '/admin/customer',
    title: "Customers",
    icon: 'face',
    class: '',
    expand : false,
    submenu:[
      {
          path: '/admin/customer/list',
          title: 'Customer List',
          icon: '',
          class:'',
          expand : false,
      },
      {
          path: '/admin/customer/add',
          title: 'Add Customer',
          icon: '',
          class:'',
          expand : false,
      }
  ]
  },
  {
    path: '/admin/client',
    title: "Clients",
    icon: 'face',
    class: '',
    expand : false,
    submenu:[]
  },
  {
    path: '/admin/vendor',
    title: "Vendor",
    icon: 'face',
    class: '',
    expand : false,
    submenu:[
      {
          path: '/admin/vendor/list',
          title: 'Vendor List',
          icon: '',
          class:'',
          expand : false,
      },
      {
          path: '/admin/vendor/add',
          title: 'Add Vendor',
          icon: '',
          class:'',
          expand : false,
      }
  ]
  },
  {
    path: '/admin/businessUser',
    title: 'Business User',
    icon: 'local_hospital',
    class: '',
    expand : false,
    submenu:[
      {
          path: '/admin/businessUser/list',
          title: 'Business User List',
          icon: '',
          class:'',
          expand : false,
      },
      {
          path: '/admin/businessUser/add',
          title: 'Add Business User',
          icon: '',
          class:'',
          expand : false,
      }
  ]
  },
  {
    path: '/admin/consulting',
  title: ' Consulting',
    icon: 'local_hospital',
    class: '',
    expand : false,
    submenu:[
      {
          path: '/admin/consulting/list',
          title: ' Consulting List',
          icon: '',
          class:'',
          expand : false,
      },

  ]
  },




  {
    path: '/admin/payment',
    title: 'Payment',
    icon: '',
    class:'',
    expand : false,
    submenu:[
        // {
        //     path: '/admin/delivery/list',
        //     title: 'Delivery Guy List',
        //     icon: '',
        //     class:'',
        //     expand : false,
        // },
        // {
        //     path: '/admin/delivery/add',
        //     title: 'Add Delivery Guy',
        //     icon: '',
        //     class:'',
        //     expand : false,
        // }
    ]
  },



//   {
//     path: '/admin/payment/list',
//     title: ' Payment',
//     icon: '',
//     class:'',
//     expand : false,
// },




  {
    path: '',
    title: 'Delivery Guy',
    icon: 'directions_bike',
    class:'',
    expand : false,
    submenu:[
        {
            path: '/admin/delivery/list',
            title: 'Delivery Guy List',
            icon: '',
            class:'',
            expand : false,
        },
        {
            path: '/admin/delivery/add',
            title: 'Add Delivery Guy',
            icon: '',
            class:'',
            expand : false,
        }
    ]
  },

  {
    path: '/admin/location',
    title: 'Location',
    icon: '',
    class: '',
    expand : false,
    submenu:[
      {
          path: '/admin/location/list',
          title: 'Location List',
          icon: '',
          class:'',
          expand : false,
      },
      {
          path: '/admin/location/add',
          title: 'Add Location',
          icon: '',
          class:'',
          expand : false,
      }
  ]
  },





  {
    path: '/admin/listing',
    title: 'Transportation Assist',
    icon: '',
    class:'',
    expand : false,
    submenu:[
        // {
        //     path: '/admin/delivery/list',
        //     title: 'Delivery Guy List',
        //     icon: '',
        //     class:'',
        //     expand : false,
        // },
        // {
        //     path: '/admin/delivery/add',
        //     title: 'Add Delivery Guy',
        //     icon: '',
        //     class:'',
        //     expand : false,
        // }
    ]
  },
  {
    path: '/admin/help',
    title: 'Queries',
    icon: '',
    class:'',
    expand : false,
    submenu:[
        // {
        //     path: '/admin/delivery/list',
        //     title: 'Delivery Guy List',
        //     icon: '',
        //     class:'',
        //     expand : false,
        // },
        // {
        //     path: '/admin/delivery/add',
        //     title: 'Add Delivery Guy',
        //     icon: '',
        //     class:'',
        //     expand : false,
        // }
    ]
  },
  // {
  //   path: '',
  //   title: 'Cat Breeds',
  //   icon: 'account_circle',
  //   class:'',
  //   expand : false,
  //   submenu:[
  //       {
  //           path: '/admin/cat-breed/list',
  //           title: 'Cat Breed List',
  //           icon: '',
  //           class:'',
  //           expand : false,
  //       },
  //       {
  //           path: '/admin/cat-breed/add',
  //           title: 'Add Cat Breed',
  //           icon: '',
  //           class:'',
  //           expand : false,
  //       }
  //   ]
  // },

  // {
  //   path: '',
  //   title: 'Dog Breeds',
  //   icon: 'account_circle',
  //   class:'',
  //   expand : false,
  //   submenu:[
  //       {
  //           path: '/admin/breed/list',
  //           title: 'Dog Breed List',
  //           icon: '',
  //           class:'',
  //           expand : false,
  //       },
  //       {
  //           path: '/admin/breed/add',
  //           title: 'Add Dog Breed',
  //           icon: '',
  //           class:'',
  //           expand : false,
  //       }
  //   ]
  // },

  // {
  //   path: '/admin/vaccineGroup',
  //   title: 'Dog Vaccine',
  //   icon: 'local_hospital',
  //   class: '',
  //   expand : false,
  //   submenu:[
  //     {
  //         path: '/admin/vaccineGroup/list',
  //         title: 'Dog Vaccine List',
  //         icon: '',
  //         class:'',
  //         expand : false,
  //     },
  //     {
  //         path: '/admin/vaccineGroup/add',
  //         title: 'Add Dog Vaccine',
  //         icon: '',
  //         class:'',
  //         expand : false,
  //     }
  // ]
  // },


  // {
  //   path: '/admin/cat-vaccine',
  //   title: 'Cat Vaccine',
  //   icon: 'local_hospital',
  //   class: '',
  //   expand : false,
  //   submenu:[
  //     {
  //         path: '/admin/cat-vaccine/list',
  //         title: 'Cat Vaccine List',
  //         icon: '',
  //         class:'',
  //         expand : false,
  //     },
  //     {
  //         path: '/admin/cat-vaccine/add',
  //         title: 'Add Cat Vaccine',
  //         icon: '',
  //         class:'',
  //         expand : false,
  //     }
  // ]
  // },


  {
    path: '',
    title: 'Appoinments',
    icon: 'departure_board',
    class: '',
    expand : false,
    submenu:[
      {
          path: '/admin/nearByAppointments',
          title: 'Nearby Appointment',
          icon: '',
          class:'',
          expand : false,
      },
      // {
      //     path: '/admin/vaccineSuggestion/add',
      //     title: 'Add Vaccine',
      //     icon: '',
      //     class:'',
      //     expand : false,
      // }
  ]
  },

  {
    path: '/admin/vaccineSuggestion',
    title: 'Medicines',
    icon: 'local_hospital',
    class: '',
    expand : false,
    submenu:[
      {
          path: '/admin/vaccineSuggestion/list',
          title: 'Medicines List',
          icon: '',
          class:'',
          expand : false,
      },
      {
          path: '/admin/vaccineSuggestion/add',
          title: 'Add Medicine',
          icon: '',
          class:'',
          expand : false,
      }
  ]
  },
  {
    path: '/admin/nearByVets',
    title: 'Nearby Vets',
    icon: '',
    class: '',
    submenu: [],
    expand : false
  },
  {
    path: '/admin/orders',
    title: 'Orders',
    icon: '',
    class: '',
    submenu: [],
    expand : false
  },


  // {
  //   path: '/admin/RxManager',
  //   title: 'Rx Manager',
  //   icon: '',
  //   class: '',
  //   submenu: [],
  //   expand : false
  // },
];

export const InventoryROUTES:any = [

  {
    path: '/vendor/food',
    title: 'Food',
    icon: 'face',
    class: '',
    expand : false,
    submenu:[
      {
          path: '/vendor/food/list',
          title: 'Food List',
          icon: '',
          class:'',
          expand : false,
      },
      {
          path: '/vendor/food/add',
          title: 'Add Food',
          icon: '',
          class:'',
          expand : false,
      }
  ]
  },


  {
    path: '/vendor/accessories',
    title: 'Accessories',
    icon: 'account_circle',
    class: '',
    expand : false,
    submenu:[
      {
          path: '/vendor/accessories/list',
          title: 'Accessories List',
          icon: '',
          class:'',
          expand : false,
      },
      {
          path: '/vendor/accessories/add',
          title: 'Add Accessories',
          icon: '',
          class:'',
          expand : false,
      }
  ]
  },
  {
    path: '/vendor/deliverys',
    title: 'Delivery',
    icon: 'directions_bike',
    class:'',
    expand : false,
    submenu:[
        {
            path: '/vendor/deliverys/list',
            title: 'Delivery List',
            icon: '',
            class:'',
            expand : false,
        },
        {
            path: '/vendor/deliverys/add',
            title: 'Add Delivery ',
            icon: '',
            class:'',
            expand : false,
        }
    ]
  },



  {
    path: '/vendor/order',
    title: 'Orders',
    icon: '',
    class:'',
    expand : false,
    submenu:[
        {
            path: '/vendor/order/list',
            title: 'Order List',
            icon: '',
            class:'',
            expand : false,
        },

    ]
  },
];
