import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { usersService } from 'src/app/services/tikaUser/user.service';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { ClientService } from 'src/app/services/crudService/client.service';
@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss']
})
export class EditClientComponent implements OnInit {

  addForm : any;
  id : any;
  Visibility:any='visibility_off'
  type:string='password';icon:string="check_circle_outline";
  icon1="check_circle";
  adoptionStatus : boolean = true;
  newDetails:any = {
    "branch":"",

  }
  constructor(private crudService : CrudServiceService,
    private activatedRoute : ActivatedRoute,
    private ClientService : ClientService , 
    private toast : ToastComponent,
    private router : Router) { 
      

    this.addForm = {
      "name":"", 
      manager:{
        "name":"",
        "email":"",
        "mobile":"",
        "role":"63e1fce02c789626ac4239ce",
        "approval":"Approved"
      },
      branch:[],
    
    }

    
    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    let filter={
      "include":["managerId","branch"]
    }
    if(this.id){
      this.ClientService.getDedtail(this.id , environment.api.clients,filter).then((res:any)=>{
        console.log(res[0],'response')
        if(res[0].managerId[0]){

          this.addForm.manager = res[0].managerId[0];
        }
        this.addForm.name = res[0].name;
        this.addForm.branch = res[0].branch;
        this.addForm.createdAt = res[0].createdAt;
        this.addForm._id = res[0]._id
        console.log(res[0].managerId,'this.addForm.manager.email')

      })
    }
    
  }

  changeVisibility()
  {
     if(this.Visibility=="visibility_off")
     {
       this.Visibility="visibility"
       this.type='text'
     }
     else if(this.Visibility=="visibility")
     {
       this.Visibility='visibility_off'
       this.type='password';
     }
  }
  addbranch(){
    this.addForm.branch.push(JSON.parse(JSON.stringify(this.newDetails)));
    this.newDetails ={
      "branch":"",
     
    }
  }
  deletebranch(index){
    this.addForm.branch.splice(index,1);
  }
  EditClient(){
        this.ClientService.editClient(this.id,environment.api.clients,this.addForm).then((res)=>{
          this.toast.toastSuccess("Client Edit successfully.");
          this.router.navigate(['/admin/client/list']);
          })
      
  }

  ngOnInit(): void {
  }
  checkAvail(){
    setTimeout(() => {
      if(this.adoptionStatus){
        this.addForm.adoptionStatus = "Adoption"
      }else{
        this.addForm.adoptionStatus = "Not Adoption"

      }
    }, 10);

  }
}

